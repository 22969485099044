export interface User {
  id: string;
  name: string;
  password?: string;
  pbCount: number;
}

export interface PersonalBest {
  id: string;
  length?: number;
  location: string;
  species: string;
  userId: string;
  weight?: number;
}

export enum Path {
  ADD_FORM = "/add",
  LISTING = "/listing",
  LOGIN = "/login",
  SIGN_UP = "/sign-up",
  USER_DISPLAY = "user-display",
}
