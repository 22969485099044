import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../config/firestore";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import bcrypt from "bcryptjs";
import { validatePassword, validateUsername } from "../utils/validation";
import Loading from "./Loading";
import ButtonLink from "./ButtonLink";
import TextButton from "./TextButton";
import { BasicForm } from "../styles/BasicForm";
import { Path, User } from "../consts";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface Props {
  users: User[];
  setUser: React.Dispatch<React.SetStateAction<User>>;
  navigate: NavigateFunction;
}

const FormSignUp = ({ users, setUser, navigate }: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (users.find((user) => user.name === username)) {
      setIsUsernameTaken(true);
      setIsLoading(false);
      return;
    }
    const salt = bcrypt.genSaltSync(10);
    const encryptedPassword = bcrypt.hashSync(password, salt);
    const user = {
      name: username,
      password: encryptedPassword,
      pbCount: 0,
    };
    try {
      const response: any = await addDoc(collection(db, "users"), user);
      const newUser = { ...user, id: response._key.path.segments[1] };
      setUser(newUser);
      navigate(Path.LISTING);
    } catch (e) {
      console.error("Error adding document: ", e);
      navigate(Path.SIGN_UP);
    }
  };

  const disableSubmit = () => {
    return !(validateUsername(username) && validatePassword(password));
  };

  return !isLoading && !isUsernameTaken ? (
    <Wrapper>
      <BasicForm onSubmit={handleSubmit}>
        <h2>Sign up</h2>
        <label htmlFor="username">Username:</label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" value="Sign up" disabled={disableSubmit()} />
      </BasicForm>
      <ButtonLink handleClick={() => navigate(Path.LOGIN)} label={"Login"} />
    </Wrapper>
  ) : isUsernameTaken ? (
    <TextButton
      label="Sorry, username taken"
      handleClick={() => setIsUsernameTaken(false)}
    />
  ) : (
    <Loading />
  );
};

export default FormSignUp;
