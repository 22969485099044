import React, { useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "./config/firestore";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import UserDisplay from "./components/UserDisplay";
import FormAdd from "./components/FormAdd";
import Listing from "./components/Listing";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FormSignIn from "./components/FormSignIn";
import FormSignUp from "./components/FormSignUp";
import Main from "./components/Main";
import { Path, User } from "./consts";

function App() {
  const [user, setUser] = useState<User>({
    id: "",
    name: "",
    pbCount: 0,
  });
  const [users, setUsers] = useState<any[]>([]);
  const [pbs, setPbs] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>({
    id: "",
    name: "",
    pbCount: 0,
  });

  const isLoggedIn = !!user?.name;

  const navigate = useNavigate();

  const getUsers = async () => {
    const q = query(collection(db, "users"));
    const querySnapshot: any = await getDocs(q);
    let data: any = [];
    querySnapshot.forEach((doc: any) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    setUsers(data);
  };

  const getPbs = async () => {
    const q = query(collection(db, "records"));
    const querySnapshot: any = await getDocs(q);
    let data: any = [];
    querySnapshot.forEach((doc: any) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    setPbs(data);
  };

  useEffect(() => {
    getPbs();
    getUsers();
  }, []);

  return (
    <div className="App">
      <Header hideAddButton={isLoggedIn} navigate={navigate} />
      <Main>
        <Routes>
          <Route
            path={Path.LISTING}
            element={
              isLoggedIn ? (
                <Listing
                  users={users}
                  pbs={pbs}
                  setSelectedUser={setSelectedUser}
                  navigate={navigate}
                />
              ) : (
                <Navigate to={Path.LOGIN} replace={true} />
              )
            }
          />
          <Route
            path={Path.USER_DISPLAY}
            element={
              isLoggedIn ? (
                <UserDisplay pbs={pbs} selectedUser={selectedUser} />
              ) : (
                <Navigate to={Path.LOGIN} replace={true} />
              )
            }
          />
          <Route
            path={Path.ADD_FORM}
            element={
              isLoggedIn ? (
                <FormAdd users={users} navigate={navigate} getPbs={getPbs} />
              ) : (
                <Navigate to={Path.LOGIN} replace={true} />
              )
            }
          />
          <Route
            path={Path.LOGIN}
            element={
              <FormSignIn users={users} setUser={setUser} navigate={navigate} />
            }
          />
          <Route
            path={Path.SIGN_UP}
            element={
              <FormSignUp users={users} setUser={setUser} navigate={navigate} />
            }
          />
          <Route
            path="*"
            element={<Navigate to={Path.LISTING} replace={true} />}
          />
        </Routes>
      </Main>
      <Footer setUser={setUser} navigate={navigate} loggedIn={isLoggedIn} />
    </div>
  );
}

export default App;
