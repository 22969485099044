import React from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import LogoPebeTrans from "../icons/LogoPeBeTrans";
import IconButton from "./IconButton";
import AddIcon from "../icons/AddIcon";
import { Path } from "../consts";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--darkColor);
  padding: 0 0.5rem;
  height: 4rem;
  border-bottom: 0.0625rem solid var(--lightColor);
`;

const HomeButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  h1 {
    display: none;
  }
  svg {
    height: 3rem;
    width: 8rem;
  }
`;

interface Props {
  hideAddButton: boolean;
  navigate: NavigateFunction;
}
const Header = ({ hideAddButton, navigate }: Props) => {
  return (
    <StyledHeader>
      <HomeButton onClick={() => navigate(Path.LISTING)}>
        <LogoPebeTrans />
        {/* hidden h1 */}
        <h1>PeBe</h1>
      </HomeButton>
      {hideAddButton && (
        <IconButton
          icon={<AddIcon />}
          handleClick={() => navigate(Path.ADD_FORM)}
        />
      )}
    </StyledHeader>
  );
};

export default Header;
