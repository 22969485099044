import styled from "styled-components";

export const BasicForm = styled.form`
  display: flex;
  flex-direction: column;
  color: var(--lightColor);
  background: var(--darkColor);
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 80%;
  > label {
    font-weight: 600;
    font-size: 0.8rem;
  }
  > select,
  input {
    background: var(--lightColor);
    color: var(--darkColor);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0.0625rem solid black;
    outline: none;
  }
  > input[type="submit"] {
    align-self: center;
    box-shadow: 0 0 0.25rem black;
    cursor: pointer;
    margin: 1rem 0 0 0;
    font-size: 1rem;
    font-weight: 600;
    height: 3rem;
    width: 10rem;
  }
  > input:disabled {
    background-color: var(--lightGray);
    color: var(--darkGray);
    box-shadow: unset;
    border: none;
    cursor: unset;
  }
`;
