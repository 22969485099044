import React from "react";
import styled from "styled-components";
import { PersonalBest, User } from "../consts";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`;

const PBList = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr {
    width: 100%;
    background: var(--baseColor);
    color: var(--lightColor);
    th,
    td {
      border: solid 0.0625rem var(--lightColor);
      font-size: 0.8rem;
    }
    th {
      font-weight: 600;
      padding: 0.25rem 0;
    }
    td {
      font-size: 0.9rem;
      padding: 0.5rem 0.25rem;
    }
    td:nth-child(1) {
      width: 30%;
    }

    td:nth-child(4) {
      width: 30%;
      text-align: end;
    }
    td:nth-child(2),
    td:nth-child(3) {
      width: 15%;
      text-align: center;
    }
  }
`;

const NoPBs = styled.td`
  text-align: center;
`;

const listPbs = (pbs: PersonalBest[]) => {
  return (
    <PBList>
      <thead>
        <tr>
          <th>Species</th>
          <th>Weight</th>
          <th>Length</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {pbs.length !== 0 ? (
          pbs.map((pb) => (
            <tr key={pb.id}>
              <td>{pb?.species}</td>
              <td>{pb?.weight ? pb.weight + "kg" : "N/A"}</td>
              <td>{pb?.length ? pb?.length + "cm" : "N/A"}</td>
              <td>{pb?.location ?? "N/A"}</td>
            </tr>
          ))
        ) : (
          <tr>
            <NoPBs colSpan={4}>No personal bests yet</NoPBs>
          </tr>
        )}
      </tbody>
    </PBList>
  );
};

interface Props {
  pbs: PersonalBest[];
  selectedUser: User;
}

const UserDisplay = ({ pbs, selectedUser }: Props) => {
  const selectedUserPbs = pbs.filter((pb) => pb.userId === selectedUser.id);

  return (
    <Wrapper>
      <h2>{selectedUser.name}</h2>
      {listPbs(selectedUserPbs)}
    </Wrapper>
  );
};
export default UserDisplay;
