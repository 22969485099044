import React from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import Loading from "./Loading";
import { Path, PersonalBest, User } from "../consts";

const StyledList = styled.ol`
  display: flex;
  flex-direction: column;
  color: rgb(90, 100, 75);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  li {
    display: flex;
    justify-content: center;
    button {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border: 0.0625rem solid rgb(90, 100, 75);
      padding: 1rem;
      margin: 0.25rem;
      font-size: 1.2rem;
      font-weight: 600;
      box-shadow: 0 0 0.25rem black;
      border-radius: 0.5rem;
      color: var(--darkColor);
      background: var(--lightColor);
    }
  }
`;

const countPbs = (pbs: PersonalBest[], userId: string) => {
  let count = 0;

  pbs.forEach((pb) => {
    if (pb.userId === userId) count++;
  });
  return count;
};

interface Props {
  users: User[];
  pbs: PersonalBest[];
  setSelectedUser: React.Dispatch<React.SetStateAction<User>>;
  navigate: NavigateFunction;
}

const Listing = ({ users, pbs, setSelectedUser, navigate }: Props) => {
  const listFishermen = () => {
    const transformedUsers: User[] = users.map((u) => {
      return {
        id: u.id,
        name: u.name,
        pbCount: countPbs(pbs, u.id),
      };
    });

    return transformedUsers?.map((tu) => (
      <li key={tu.id}>
        <button
          onClick={() => {
            setSelectedUser({
              id: tu.id,
              name: tu.name,
              pbCount: tu.pbCount,
            });
            navigate(Path.USER_DISPLAY);
          }}
        >
          <span>{tu.name}</span>
          <span>{tu.pbCount}</span>
        </button>
      </li>
    ));
  };
  return users.length > 0 ? (
    <StyledList>{listFishermen()}</StyledList>
  ) : (
    <Loading />
  );
};

export default Listing;
