import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  color: var(--lightColor);
  padding: 1rem;
  margin: 1rem;
  width: 5rem;
`;

interface Props {
  label: string;
  handleClick: () => void;
}

const ButtonLink = ({ handleClick, label }: Props) => {
  return <StyledButton onClick={handleClick}>{label}</StyledButton>;
};

export default ButtonLink;
