import React from "react";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";
import { Path, User } from "../consts";

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  font-size: 0.6rem;
  background: var(--darkColor);
  border-top: 0.0625rem solid var(--lightColor);
`;
const Mail = styled.a`
  text-decoration: none;
  color: var(--lightColor);
  border-left: 0.0625rem solid var(--lightColor);
  border-right: 0.0625rem solid var(--lightColor);
  padding: 0 0.5rem;
  margin: 0 0.5rem;
`;
const Year = styled.p`
  color: var(--lightColor);
`;
const FooterButton = styled.button`
  background: transparent;
  border: none;
  font-size: 0.6rem;
  color: var(--lightColor);
  padding: 0;
  cursor: pointer;
`;

interface Props {
  setUser: React.Dispatch<React.SetStateAction<User>>;
  navigate: NavigateFunction;
  loggedIn: boolean;
}

const Footer = ({ setUser, navigate, loggedIn }: Props) => {
  return (
    <StyledFooter>
      <>
        <FooterButton
          onClick={() => {
            setUser({
              id: "",
              name: "",
              pbCount: 0,
            });
            navigate(Path.LOGIN);
          }}
        >
          {loggedIn ? "Log out" : "Log in"}
        </FooterButton>
      </>
      <Mail href="mailto:info@stefansjoman.se">info@stefansjoman.se</Mail>
      <Year>2023</Year>
    </StyledFooter>
  );
};

export default Footer;
