import React, { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { BasicForm } from "../styles/BasicForm";
import styled from "styled-components";
import bcrypt from "bcryptjs";
import Loading from "./Loading";
import ButtonLink from "./ButtonLink";
import TextButton from "./TextButton";
import { Path, User } from "../consts";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface Props {
  users: User[];
  setUser: React.Dispatch<React.SetStateAction<User>>;
  navigate: NavigateFunction;
}

const FormSignIn = ({ users, setUser, navigate }: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userTriedToLogin, setUserTriedToLogin] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const user = users.find((user) => user.name === username);
    if (user) {
      const isSuccess = bcrypt.compareSync(password, user.password ?? "");
      if (isSuccess) {
        setUser(user);
        navigate(Path.LISTING);
        return;
      }
    }
    setUserTriedToLogin(true);
  };

  const disableSubmit = () => {
    return !(username && password);
  };

  return !isLoading ? (
    <Wrapper>
      <BasicForm onSubmit={handleSubmit}>
        <h2>Log in</h2>
        <label htmlFor="username">Username:</label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" value="Log in" disabled={disableSubmit()} />
      </BasicForm>
      <ButtonLink
        handleClick={() => navigate(Path.SIGN_UP)}
        label={"Sign up"}
      />
    </Wrapper>
  ) : userTriedToLogin ? (
    <TextButton
      label={"Login failed"}
      handleClick={() => {
        setIsLoading(false);
        setUserTriedToLogin(false);
      }}
    />
  ) : (
    <Loading />
  );
};

export default FormSignIn;
