import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`;

interface Props {
  children?: JSX.Element;
}

const Main = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Main;
