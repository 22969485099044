import React from "react";

const AddIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="22" y="12" width="4" height="24" rx="2" fill="#2F3526" />
      <rect x="12" y="22" width="24" height="4" rx="2" fill="#2F3526" />
    </svg>
  );
};

export default AddIcon;
