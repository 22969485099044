import React from "react";
import styled from "styled-components";

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 2rem;
  width: 6rem;
  background: var(--darkColor);
  border-radius: 1rem;
  > div {
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    background: var(--lightColor);
    animation: crescendo 0.6s alternate infinite ease-in;
    @keyframes crescendo {
      0% {
        transform: scale(0.5);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  > div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const Loading = () => {
  return (
    <Loader>
      <div />
      <div />
      <div />
    </Loader>
  );
};

export default Loading;
