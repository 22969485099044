import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lightColor);
  color: var(--darkColor);
  box-shadow: 0 0 0.25rem black;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  width: 12rem;
  border-radius: 0.5rem;
`;

interface Props {
  label: string;
  handleClick: () => void;
}

const TextButton = ({ handleClick, label }: Props) => {
  return <StyledButton onClick={() => handleClick()}>{label}</StyledButton>;
};

export default TextButton;
