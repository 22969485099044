import React, { useEffect, useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../config/firestore";
import { NavigateFunction } from "react-router-dom";
import Loading from "./Loading";
import TextButton from "./TextButton";
import { BasicForm } from "../styles/BasicForm";
import { Path, User } from "../consts";

const getOptions = (users: User[]) => {
  const options = users.map((u) => (
    <option key={u.id} value={u.id}>
      {u.name}
    </option>
  ));
  options.unshift(
    <option key={"select"} value={""} disabled>
      Select
    </option>
  );
  return options;
};

interface Props {
  users: User[];
  navigate: NavigateFunction;
  getPbs: any;
}

const FormAdd = ({ users, navigate, getPbs }: Props) => {
  const [userId, setUserId] = useState("");
  const [species, setSpecies] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [location, setLocation] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    if (users.length > 0) setIsLoading(false);
  }, [users]);

  const handleSelect = (e: any) => {
    setUserId(e.target.value);
  };
  const commaToPeriod = (text: string) => {
    return text.replace(",", ".");
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (weight === "") setWeight("0");
    if (length === "") setLength("0");
    setIsLoading(true);

    try {
      await addDoc(collection(db, "records"), {
        userId: userId,
        species: species,
        weight: weight,
        length: length,
        location: location,
      });
      getPbs().then(() => setIsCreated(true));
    } catch (e) {
      console.error("Error adding document: ", e);
      setIsCreated(false);
    }
  };
  const disableSubmit = () => {
    return !(userId && species && location && (weight !== "" || length !== ""));
  };

  return !isLoading && !isCreated ? (
    <BasicForm onSubmit={handleSubmit}>
      <h2>Add PB</h2>
      <label htmlFor="user">Catcher:</label>
      <select value={userId} onChange={handleSelect}>
        {getOptions(users)}
      </select>
      <label htmlFor="species">Species:</label>
      <input
        id="species"
        type="text"
        value={species}
        onChange={(e) => setSpecies(e.target.value)}
      />
      <label htmlFor="weight">Weight (kg):</label>
      <input
        id="weight"
        type="text"
        value={weight}
        onChange={(e) => setWeight(commaToPeriod(e.target.value))}
      />
      <label htmlFor="length">Length (cm):</label>
      <input
        id="length"
        type="text"
        value={length}
        onChange={(e) => setLength(e.target.value)}
      />
      <label htmlFor="location">Location:</label>
      <input
        id="location"
        type="text"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
      <input type="submit" value="Add" disabled={disableSubmit()} />
    </BasicForm>
  ) : !isCreated && isLoading ? (
    <Loading />
  ) : (
    <TextButton
      handleClick={() => navigate(Path.LISTING)}
      label={isCreated ? "Added!" : "Failed..."}
    />
  );
};

export default FormAdd;
