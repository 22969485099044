import React from "react";
import styled from "styled-components";

interface Props {
  icon: JSX.Element;
  handleClick: () => void;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 3rem;
  width: 3rem;
  background: var(--lightColor);
  color: var(--darkColor);
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.25rem black;
`;
const IconButton = ({ icon, handleClick }: Props) => {
  return (
    <StyledButton onClick={() => handleClick()}>
      <>{icon}</>
    </StyledButton>
  );
};

export default IconButton;
